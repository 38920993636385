import I18n from '../i18n/I18n';
const exception = (errorCode) => {
  return {
    defaults: [{ scope: 'serverErrorMessages.50000' }],
    originalErrorCode: errorCode,
  };
};

export const manageError = (errorCode) => {
  switch (errorCode) {
  case 703108:
    return I18n.t(`serverErrorMessages.${['703108']}`, exception(errorCode));
  case 703111:
    return I18n.t(`serverErrorMessages.${['703111']}`, exception(errorCode));
  case 703112:
    return I18n.t(`serverErrorMessages.${['703112']}`, exception(errorCode));
  case 703117:
    return I18n.t(`serverErrorMessages.${['703117']}`, exception(errorCode));
  case 703201:
    return I18n.t(`serverErrorMessages.${['703201']}`, exception(errorCode));
  case 703202:
    return I18n.t(`serverErrorMessages.${['703202']}`, exception(errorCode));
  case 703203:
    return I18n.t(`serverErrorMessages.${['703203']}`, exception(errorCode));
  case 703104:
    return I18n.t(`serverErrorMessages.${['703104']}`, exception(errorCode));
  case 703106:
    return I18n.t(`serverErrorMessages.${['703106']}`, exception(errorCode));
  default:
    return I18n.t(`serverErrorMessages.${[`${errorCode}`]}`, exception(errorCode));
  }
};
